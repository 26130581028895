import React from 'react'
import StartPage from '../main'

export default function EnPage() {
    return (
		<StartPage
			language={'en'}
		/>
    )
}

